/*body {*/
/*    transform-origin: top center;*/
/*    -webkit-transform-origin: top center;*/
/*    -moz-transform-origin: top center;*/
/*    -ms-transform-origin: top center;*/
/*    -o-transform-origin: top center;*/
/*    margin: 0;*/
/*    padding: 0;*/
/*    overflow: hidden; !* Ensure no scrollbars *!*/
/*}*/

/*!* Extra small devices (phones, less than 576px) *!*/
/*@media (max-width: 575.98px) {*/
/*    body {*/
/*        transform: scale(0.8);*/
/*    }*/
/*}*/

/*!* Small devices (tablets, 576px and up) *!*/
/*@media (min-width: 576px) and (max-width: 767.98px) {*/
/*    body {*/
/*        transform: scale(0.9);*/
/*    }*/
/*}*/

/*!* Medium devices (desktops, 768px and up) *!*/
/*@media (min-width: 768px) and (max-width: 991.98px) {*/
/*    body {*/
/*        transform: scale(1);*/
/*    }*/
/*}*/

/*!* Large devices (desktops, 992px and up) *!*/
/*@media (min-width: 992px) and (max-width: 1199.98px) {*/
/*    body {*/
/*        transform: scale(1.1);*/
/*    }*/
/*}*/

/*!* Extra large devices (desktops, 1200px and up) *!*/
/*@media (min-width: 1200px) {*/
/*    body {*/
/*        transform: scale(1.2);*/
/*    }*/
/*}*/



.remove-right-padding {

    padding-right: 0em;
}

.remove-left-padding {

    padding-left: 0em;
}

.bg-new-dark-blue {
    background-color: #001689;
    color: white;
    border-radius: 0 !important;
}

.bg-new-light-blue {
    background-color: #6188bc;
    color: white;
    border-radius: 0 !important;
}

.legendKey {
    font-weight: bold;
    color: #001689;
}

#legend {
    font-size: small;
}

.heading{
    color: #001689;
    text-decoration: underline;
    text-align: center;
}

.visible-xs, .visible-sm, .visible-md, .visible-lg {
    display: none;
}

/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
    .visible-xs {
        display: inline;
    }
}

/* Small devices (tablets, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
    .visible-sm {
        display: inline;
    }
}

/* Medium devices (desktops, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    .visible-md {
        display: inline;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .visible-lg {
        display: inline;
    }
}
