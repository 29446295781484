//.custom {
  .row{
    margin: 0 !important;
  }

.nav-tabs .nav-link, .nav-tabs .nav-item .nav-link {
  --tab-active-color: #536a82;
  --tab-inactive-color: #708398cc;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  height: 3rem;
  //border-top-width: 3px;
  //border-top-style: solid;
  //border-left-width: 1px;
  //border-left-style: solid;
  //border-right-width: 1px;
  //border-right-style: solid;
  padding: 0px 0.5rem 0px 1rem;
  border-radius: 0 !important;
  flex: 1 1 0px;
  //width: 0px;
  //max-width: 240px;
  min-width: 240px;
  cursor: pointer;
  position: relative;
  justify-content: space-around;
  border-color: var(--tab-active-color);
  box-shadow: rgb(255, 255, 255) 0px 1px;
  color: var(--tab-inactive-color);
  opacity: 1;
  border: 0;
}

.nav-tabs .nav-link.active::after, .nav-tabs .nav-item .nav-link.active::after {
  content: "";
  height: 1.7rem;
  width: 1px;
  position: absolute;
  right: 0px;
  margin-right: -2px;
  opacity: 0.25;
  background-color: unset;
}

.nav-tabs .nav-link::after, .nav-tabs .nav-item .nav-link::after {
  content: "";
  height: 1.7rem;
  width: 1px;
  position: absolute;
  right: 0px;
  margin-right: -2px;
  opacity: 0.25;
  background-color: rgb(117, 135, 155);
}


.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  --tab-active-color: #536a82;
  --tab-inactive-color: #708398cc;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  height: 3rem;
  border-top-width: 3px;
  border-top-style: solid;
  border-left-width: 1px;
  border-left-style: solid;
  border-right-width: 1px;
  border-right-style: solid;
  padding: 0px 0.5rem 0px 1rem;
  border-radius: 0 !important;
  flex: 1 1 0px;
  //width: 0px;
  //max-width: 240px;
  min-width: 240px;
  cursor: pointer;
  position: relative;
  justify-content: space-around;
  border-color: var(--tab-active-color);
  box-shadow: rgb(255, 255, 255) 0px 1px;
  color: var(--tab-active-color);
  opacity: 1;
  border-bottom: 0 !important;
}

.card {
  border-radius: 0 !important;
}

.btn {
  border-radius: 0 !important;
}

.picasa-button.outline.primary {
  color: rgb(8, 126, 166);
  background-color: transparent;
  border-color: rgb(8, 126, 166);
}
.picasa-button.medium {
  font-size: 1rem;
}

.picasa-button.medium {
  height: 2.1429rem;
}

.picasa-button.outline.primary.active {
  background-color: rgb(8, 126, 166);
  color: #ffffff;
}

.picasa-button.outline.primary.active:hover, .picasa-button.outline.primary.active:focus {
  background-color: rgb(8, 126, 166);
  color: #ffffff;
}

.border_translucent{
  border-color: rgba(8, 10, 12, 0.175);
}

.form-multi-select-input-group:focus, .form-control:focus{
  border-color: rgb(7, 109, 143);
  box-shadow: 0 0 0 0.25rem rgb(7, 109, 143, 0.25);
}

.form-multi-select-input-group, .form-control, .form-select, .form-multi-select-dropdown{
  border-radius: 0 !important;
}

.form-multi-select.show .form-multi-select-input-group, .form-select:focus {
  border-color: rgb(7, 109, 143);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(7, 109, 143, 0.25);
}



.picasa-button {
  padding: 0px 1.0714rem;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  border-width: 0.0714rem;
  border-style: solid;
  border-radius: 0.1429rem;
}

.picasa-button.outline.primary:hover {
  background-color: rgba(7, 109, 143, 0.05);
}
.picasa-button.outline.primary:focus, .picasa-button.outline.primary:hover {
  color: rgb(7, 109, 143);
  border-color: rgb(7, 109, 143);
}

.page-link{
  color: rgb(8, 126, 166) !important;
}

.page-link.active, .active > .page-link{
  background-color: rgb(8, 126, 166) !important;
  border-color: rgb(8, 126, 166) !important;
  color: white !important;
}

.toast{
  background-color: rgba(var(--cui-body-bg-rgb)) !important;
}
