/*#border {*/
/*  border: 1px solid grey;*/
/*  border-radius: 0px;*/
/*  margin-left: 0em;*/
/*  margin-right: 0em;*/
/*  margin-top: 0em;*/
/*  margin-bottom: 0em;*/
/*  flex-shrink: 0;*/
/*  !* width: 100% !important; *!*/
/*  !* height: 100% !important; *!*/
/*}*/

/*#full-app {*/
/*  height: 100%;*/
/*}*/

/*#apps-container {*/
/*    width: 100%;*/
/*    height: 100%;*/
/*}*/

/*#details-container {*/
/*    margin: 1em;*/
/*    !* height: 100%; *!*/
/*}*/

/*#upper-container {*/
/*    width: 100% !important;*/
/*    height: 100% !important;*/
/*}*/

/*#lower-container{*/
/*    width: 100%;*/

/*}*/

/*fieldset {*/
/*    padding: 1em !important;*/
/*    font-size: 1.2em;*/
/*}*/

/*.header-border {*/
/*  border: 1px solid black !important;*/
/*}*/

/*.details-border {*/
/*  border: 1px solid grey;*/
/*  border-radius: 5px;*/
/*  margin-left: .5em;*/
/*  margin-right: .5em;*/
/*  margin-top: .5em;*/
/*  margin-bottom: .5em;*/
/*  flex-shrink: 0;*/
/*  align-items: stretch;*/
/*  display: flex;*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  overflow: auto;*/
/*}*/



/*.App {*/
/*  text-align: center;*/
/*}*/

/*.App-logo {*/
/*  height: 40vmin;*/
/*  pointer-events: none;*/
/*}*/

/*@media (prefers-reduced-motion: no-preference) {*/
/*  .App-logo {*/
/*    animation: App-logo-spin infinite 20s linear;*/
/*  }*/
/*}*/

/*.App-header {*/
/*  background-color: #282c34;*/
/*  min-height: 100vh;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  font-size: calc(10px + 2vmin);*/
/*  color: white;*/
/*}*/

/*.App-link {*/
/*  color: #61dafb;*/
/*}*/

/*@keyframes App-logo-spin {*/
/*  from {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  to {*/
/*    transform: rotate(360deg);*/
/*  }*/
/*}*/

/*#td {*/
/*  text-align: center;*/
/*  width: 10%;*/
/*  text-wrap: true;*/
/*}*/

/*#tbody #tr:nth-child(odd) {*/
/*  background-color: #f1fcfe;*/
/*  !* color: #fff; *!*/
/*}*/