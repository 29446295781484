#headerBar {
    padding-right: 20px;
    background-color: #26303b !important;
    color: white;
}

.offcanvas {
    position: fixed;
    bottom: inherit !important;
    right: 0 !important;
    height: 100%;
    width: 50vh;
    box-shadow: 0 .5rem 1rem rgba(8, 10, 12, .15);
}

#notification {
    padding-top: 5px;
}

/* Switch Styles */
.custom-switch .form-check-input:checked {
    background-color: #28a745 !important; /* Green color for active state */
    border-color: #28a745 !important; /* Green border for active state */
    box-shadow: 0 0 0 0.25rem rgba(40, 167, 69, 0.25) !important; /* Green glow effect */
}

.custom-switch .form-check-input {
    width: 3rem; /* Larger switch width */
    height: 1.5rem; /* Larger switch height */
    border-radius: 1rem; /* Rounded edges for the switch */
    background-color: #ccc !important; /* Default gray for inactive */
    border-color: #ccc !important;
    transition: all 0.3s ease-in-out; /* Smooth animation */
}

#offCanvasBody {
    padding: 0 !important;
}

#offCanvasHeader {
    margin-bottom: 0 !important;
    border-bottom: rgba(8, 10, 12, 0.175) solid 1px;
}

.notificationDate {
    font-size: x-small;
    color: rgba(37, 43, 54, 0.95);
}

.notificationHeader {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    margin-right: 0;
}

.notificationFooter {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    margin-right: 0;
    font-size: x-small;
    color: rgba(37, 43, 54, 0.95);
}

.notificationMessageBody {
    margin-top: 8px;
    white-space: nowrap;
    overflow: hidden;
    max-height: 50px;
    text-overflow: ellipsis;
}

#notifiactionDateCard {
    border-width: 0 !important;
}

.messageContainer {
    border-color: rgba(8, 10, 12, 0.175) !important;
    cursor: pointer;
}

.messageContainer:hover {
    box-shadow: 0 .1rem 1rem rgba(8, 10, 12, .15);
}

#notificationDateCardHeader {}

#notificationDateCardBody {
    padding: 0 !important;
}

#scheduleLink {
    width: 30px;
}

.scheduleIconBox {
    width: 8%;
    color: white;
}

.scheduleIconBox::after {
    content: "";
    margin-left: 15px;
    height: 100%;
    width: 0px;
    border-left: 1px solid rgb(15, 20, 25);
    border-right: 1px solid rgb(67, 84, 101);
}

#vertical {
    border-right: 1px solid rgb(90, 90, 90);
    border-left: 1px solid rgb(26, 26, 26);
    height: 2em;
    margin-top: -4px;
}

#gtsLogo {
    display: inline-block;
    min-width: 100px;
    min-height: 30px;
    max-width: 100px;
    margin-top: .75em;
    padding-right: 1em;
    white-space: nowrap;
    overflow: hidden;
    background-color: #26303b;
    color: white;
}

#gtsHeader {
    white-space: nowrap;
    overflow: hidden;
    background-color: #26303b;
    color: white;
}

.alert {
    position: relative;
    top: 0;
    left: 0;
    width: auto;
    height: 20px;
    padding: 10px;
    margin-top: -10px;
    line-height: 1.8;
    cursor: pointer;
    font-family: sans-serif;
    font-weight: 400;
}

.alertCheckbox {
    display: none;
}

:checked + .alert {
    display: none;
}

.alertText {
    display: table;
    color: #d7d6d6;
    margin: 0 auto;
    text-align: center;
    font-size: 16px;
}

.alertClose {
    float: right;
    padding-top: 4px;
    font-size: 10px;
    margin-left: 5px;
    color: #000000;
}

.clear {
    clear: none;
}

.info {
    background-color: #EEE;
    border: 1px solid #DDD;
    color: #999;
}

.success {
    background-color: #EFE;
    border: 1px solid #DED;
    color: #9A9;
}

.notice {
    background-color: #384757;
    border: 1px solid #26303b;
    color: #9AA;
}

.warning {
    background-color: #FDF7DF;
    border: 1px solid #FEEC6F;
    color: #C9971C;
}

.error {
    background-color: #FEE;
    border: 1px solid #EDD;
    color: #A66;
}
